<template>
  <div
    v-if="status"
    class="font-medium text-sm text-green-600"
  >
    {{ status }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

const status = computed(() => usePage().props.sessionStatus);
</script>
